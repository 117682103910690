











import ForgotPassword from "@/components/Auth/ForgotPassword.vue";
import SignIn from "@/components/Auth/SignIn.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    SignIn,
    ForgotPassword,
  },
})
export default class SignInView extends Vue {
  login = true;
}
