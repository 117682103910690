





















































import { notEmpty } from "@/helpers/form.validators";
import { CoreAuthActions } from "@/spect8-core-vue/src/types";
import { Vue, Component } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({})
export default class ForgotPassword extends Vue {
  formIsValid = false;
  email = "";
  submitted = false;
  fieldRules = [notEmpty];

  @Action(CoreAuthActions.ForgotPasswordFusionAuth)
  forgotPasswordFusionAuth!: (loginId: string) => Promise<boolean>;

  async sendForgotPasswordEmail() {
    await this.forgotPasswordFusionAuth(this.email);
    this.submitted = true;
  }
}
