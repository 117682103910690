































































import {
  CoreAuthActions,
  LoginFusionAuthPayload,
} from "@/spect8-core-vue/src/types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({})
export default class SignInForm extends Vue {
  email = "";
  password = "";

  loading = false;
  formIsValid = false;
  showPassword = false;

  emailRules: Array<(value: string) => true | string> = [
    (v: string) => !!v || this.$t("Email is required").toString(),
    (v: string) =>
      /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      this.$t("E-mail must be valid").toString(),
  ];

  passwordRules: Array<(value: string) => true | string> = [
    (v: string) => !!v || this.$t("Password is required").toString(),
    (v: string) =>
      (v && v.length >= 5) ||
      this.$t("Password must have 5+ characters").toString(),
  ];

  @Action(CoreAuthActions.LoginFusionAuth)
  loginFusionAuth!: (payload: LoginFusionAuthPayload) => Promise<boolean>;

  get form(): Vue & {
    validate: () => boolean;
    resetValidation: () => boolean;
  } {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      resetValidation: () => boolean;
    };
  }

  @Watch("email")
  onPropertyChanged() {
    this.form.resetValidation();
  }

  async submitForm() {
    this.loading = true;
    await this.loginFusionAuth({
      loginId: this.email,
      password: this.password,
    });
    this.loading = false;
  }
}
